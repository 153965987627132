import React, { useState } from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import purple from '@material-ui/core/colors/purple'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { calcBounceImpact, calcConvImpact } from './utils'
import Chart from './Chart'
import Logo from './logo.svg'

function Copyright () {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://edgemesh.com/'>
        Edgemesh Corporation
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  good: {
    color: theme.palette.success.main
  },
  bad: {
    color: theme.palette.error.main
  }
}))

export default function App () {
  const classes = useStyles()
  const [currentValue, setCurrentValue] = useState(4.0)
  const [newValue, setNewValue] = useState(1.2)
  const values = { currentValue, setCurrentValue, newValue, setNewValue }

  const theme = createMuiTheme({
    palette: {
      primary: purple,
      secondary: green,
      success: green,
      error: red
    }
  })

  const bad = values.currentValue < values.newValue
  const verbs = [
    bad ? ' increased ' : ' decreased ',
    bad ? ' rise ' : ' drop ',
    bad ? ' decline ' : ' improve '
  ]

  const bounce = Math.abs(calcBounceImpact(values.currentValue, values.newValue) * 100).toFixed(0)
  const convert = Math.abs(calcConvImpact(values.currentValue, values.newValue) * 100).toFixed(0)

  return (
    <ThemeProvider theme={theme}>
      <Grid container component='main' className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img src={Logo} width={50} height={50} />
            <br />
            <Typography component='h1' variant='h5'>
              Speed Impact Calculator
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='current'
                label='Current TTI (seconds)'
                name='current'
                type='number'
                defaultValue={values.currentValue}
                inputProps={{
                  min: 1
                }}
                onChange={(event) => {
                  values.setCurrentValue(event.target.value)
                }}
                autoFocus
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='new'
                label='New TTI (seconds)'
                id='new'
                type='number'
                value={values.newValue}
                inputProps={{
                  min: 1
                }}
                onChange={(event) => {
                  let value = event.target.value
                  if (value <= 1) value = 1
                  values.setNewValue(event.target.value)
                }}
              />

              <Box mt={5}>
                <Typography align='center'>
                  If your time to interactive was
                  <span className={bad ? classes.bad : classes.good}> {values.currentValue} </span>
                  seconds and you
                  <span className={bad ? classes.bad : classes.good}> {verbs[0]} </span>
                  your time to interactive to
                  <span className={bad ? classes.bad : classes.good}> {values.newValue} </span>
                  seconds, then your bounce rate would
                  <span className={bad ? classes.bad : classes.good}> {verbs[1]} ~{bounce}% </span>
                  and your conversion rates would
                  <span className={bad ? classes.bad : classes.good}> {verbs[2]} ~{convert}% </span>
                  .
                </Typography>
              </Box>

              <Box mt={5}>
                <Link href='https://developers.google.com/web/tools/lighthouse/audits/time-to-interactive' variant='body2'>
                  <Typography align='center'>What is Time to Interactive (TTI)</Typography>
                </Link>
                <Link href='https://www.portent.com/blog/analytics/research-site-speed-hurting-everyones-revenue.htm' variant='body2'>
                  <Typography align='center'>Other Studies</Typography>
                </Link>
              </Box>

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>

        <Grid item xs={false} sm={4} md={7} className={classes.image}>
          <Chart {...values} />
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}
