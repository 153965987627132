import React from 'react'
import { XYPlot, XAxis, YAxis, VerticalBarSeries, VerticalGridLines, HorizontalGridLines } from 'react-vis'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { calcBounceImpact, calcConvImpact } from './utils'
import '../node_modules/react-vis/dist/style.css'

export default function Chart ({ currentValue, newValue }) {
  const bounceData = []
  const convertData = []
  const xAxisValues = []
  const purple = '622B7A'

  let nv = currentValue
  for (let i = 0; i < 10; i++) {
    if (nv <= 1) nv = 1
    bounceData.push(calcBounceImpact(currentValue, nv))
    convertData.push(calcConvImpact(currentValue, nv))
    xAxisValues.push(nv)
    nv -= 0.25
  }

  return (
    <div style={{
      width: 'fit-content',
      margin: '0 auto',
      paddingTop: '5em'
    }}>
      <Box mt={5}>
        <Typography component='h1' variant='h5'>
          Bounce Rate
        </Typography>
        <XYPlot height={300} width={600}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis title='Time to Interactive' tickTotal={10} position='start' tickFormat={(v, i) => `${xAxisValues[i]}s`} />
          <YAxis title='Bounce Rate' tickTotal={10} tickFormat={v => `${(v * 100).toFixed(0)}%`} />
          <VerticalBarSeries data={bounceData.map((y, x) => ({ x, y }))} color={purple} />
        </XYPlot>
      </Box>

      <Box mt={5}>
        <Typography component='h1' variant='h5'>
          Conversion Rate
        </Typography>
        <XYPlot height={300} width={600}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis title='Time to Interactive' tickTotal={10} position='start' tickFormat={(v, i) => `${xAxisValues[i]}s`} />
          <YAxis title='Conversion Rate' tickTotal={10} tickFormat={v => `${(v * 100).toFixed(0)}%`} />
          <VerticalBarSeries data={convertData.map((y, x) => ({ x, y }))} color={purple} />
        </XYPlot>
      </Box>
    </div>
  )
}
